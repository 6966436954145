import { createRouter, createWebHistory } from 'vue-router'
import Auth from '@/modules/freetier/services/authservice';
import { store } from '@/store';
import PagesAccess from '@/helper/pagesaccess';
const router = createRouter({
    history: createWebHistory(),
    routes: []
})
router.beforeEach((to, from, next) => {
    let userLocation = localStorage.getItem('location');
    if (to.meta.requiresAuth) {
        if (store.getters['freetier/isAuthenticated'] &&  to.meta.isFreeTierAccess) {
            if (userLocation == to.meta.location) {
                next()
            } else {
                next()
            }
        }
        if (store.getters['client/isAuthenticated'] && to.meta.isClientAccess) {
            store.dispatch("client/setLoginPopUp", false)        
            let PageExcluded =  PagesAccess.getPageRemoveAccess(store.getters['client/StateUser']?.email);       
            if(!userLocation){
                localStorage.setItem("location", "Singapore")
            }
            if (userLocation == to.meta.location) {              
                if(PageExcluded.includes('CandidateDatabase') && to?.name == "CandidateDatabase" ){
                    next('/client/v2/my-orders')
                }else{
                    next()
                }      
            } else {              
                if(PageExcluded.includes('CandidateDatabase') && to?.name == "CandidateDatabase" ){
                    next('/client/v2/my-orders')
                }else{
                    next()
                }              
            }
        }
        if (store.getters['talent/isAuthenticated'] && to.meta.isTalentAccess) {
            next()
        } else {
            if (to.meta.isClientAccess) {
                if(!userLocation){
                    localStorage.setItem("location", "Any")
                }
                if(store.getters['client/isAuthenticated']){
                    store.dispatch("client/setLoginPopUp", false)
                }else{
                    store.dispatch("client/setRedirect", to?.fullPath)
                    store.dispatch("client/setLoginPopUp", true)
                }              
                next('/client')
            }
             else if (to.meta.isTalentAccess) {
                next('/talent')
            } else if (to.meta.isFreeTierAccess) {
                if(to?.name == "JobDetails" && !store.getters['freetier/isAuthenticated']){
                    store.dispatch("freetier/setRedirect", to?.fullPath)
                    next("/free/candidatedb?islogin=true")
                }        
                next('/free/candidatedb')
            } else {
                next('/')
            }           
        }
    } else {
        if(to?.name == "CandidateDatabaseTable"){            
            store.dispatch("client/setRedirect", "")
            store.dispatch("client/setLoginPopUp", false)
        }
        next();     
    }
})
export default router